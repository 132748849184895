<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal_form_vehiculo_export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar listado Vehiculos</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="placa" class="col-md-5">Placa</label>
                    <input
                      type="text"
                      id="placa"
                      v-model="filtros.placa"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="tipo_vehiculo_id" class="col-md-5"
                      >Tipo de Vehiculo</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_vehiculo_id"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_vehiculo in listasForms.tipos_vehiculos"
                        :key="tipo_vehiculo.id"
                        :value="tipo_vehiculo.id"
                      >
                        {{ tipo_vehiculo.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="empresa_id" class="col-md-5">Empresa</label>
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.empresa_id"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="empresa in listasForms.empresas"
                        :key="empresa.id"
                        :value="empresa.id"
                      >
                        {{ empresa.razon_social }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="tipo_carroceria" class="col-md-5"
                      >Tipo de Carrocería</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_carroceria"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_carroceria in listasForms.tipos_carrocerias"
                        :key="tipo_carroceria.numeracion"
                        :value="tipo_carroceria.numeracion"
                      >
                        {{ tipo_carroceria.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="numero_motor" class="col-md-5"
                      >Numero de Motor</label
                    >
                    <input
                      type="text"
                      id="numero_motor"
                      v-model="filtros.numero_motor"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="modelo" class="col-md-5"> Modelo</label>
                    <input
                      type="number"
                      id="Modelos"
                      v-model="filtros.modelo"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="numero_chasis" class="col-md-5">
                      Numero de Chasis</label
                    >
                    <input
                      type="number"
                      id="numero_chasis"
                      v-model="filtros.numero_chasis"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="color" class="col-md-5">Color</label>
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.color"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="color in listasForms.colores"
                        :key="color.numeracion"
                        :value="color.numeracion"
                      >
                        {{ color.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="marca" class="col-md-5">Marca</label>
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.marca"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="marca in listasForms.marcas"
                        :key="marca.numeracion"
                        :value="marca.numeracion"
                      >
                        {{ marca.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="tipo_capacidad" class="col-md-5"
                      >Tipo de Capacidad</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_capacidad"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_capacidad in listasForms.tipos_capacidad"
                        :key="tipo_capacidad.numeracion"
                        :value="tipo_capacidad.numeracion"
                      >
                        {{ tipo_capacidad.descripcion }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group row">
                    <label for="estado" class="col-md-5"> Estado</label>
                    <select
                      id="estado"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.estado"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="tipo_propiedad" class="col-md-5"
                      >Propiedad</label
                    >
                    <select
                      id="tipo_propiedad"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_propiedad"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_propiedad in listasForms.tipos_propiedad"
                        :key="tipo_propiedad.numeracion"
                        :value="tipo_propiedad.numeracion"
                      >
                        {{ tipo_propiedad.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="tipo_combustible" class="col-md-5"
                      >Tipo de Combustible</label
                    >
                    <select
                      id="tipo_combustible"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_combustible"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_combustible in listasForms.tipos_combustible"
                        :key="tipo_combustible.numeracion"
                        :value="tipo_combustible.numeracion"
                      >
                        {{ tipo_combustible.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="placa1"
                              v-model="form.placa"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="placa1"
                              >Placa</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_vehiculo_id"
                              v-model="form.tipo_vehiculo_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_vehiculo_id"
                              >Tipo de Vehiculo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="empresa_id"
                              v-model="form.empresa_id"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="empresa_id"
                              >Empresa</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tarjeta_propiedad"
                              v-model="form.tarjeta_propiedad"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tarjeta_propiedad"
                              >Tarjeta de Propiedad</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_carroceria"
                              v-model="form.tipo_carroceria"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_carroceria"
                              >Tipo de Carrocería</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="numero_motor1"
                              v-model="form.numero_motor"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="numero_motor1"
                              >Número de Motor</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="modelo"
                              v-model="form.modelo"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="modelo"
                              >Modelo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="peso_vacio"
                              v-model="form.peso_vacio"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="peso_vacio"
                              >Peso Vacio</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="modelo_re_potenciado"
                              v-model="form.modelo_re_potenciado"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="modelo_re_potenciado"
                              >Modelo re Potenciado</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="numero_chasis1"
                              v-model="form.numero_chasis"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="numero_chasis1"
                              >Numero de Chasis</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="color"
                              v-model="form.color"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="color"
                              >Color</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="marca"
                              v-model="form.marca"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="marca"
                              >Marca</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_capacidad"
                              v-model="form.tipo_capacidad"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_capacidad"
                              >Tipo de Capacidad</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="estado1"
                              v-model="form.estado"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="estado1"
                              >Estado</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_propiedad1"
                              v-model="form.tipo_propiedad"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_propiedad1"
                              >Tipo de Propiedad</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_combustible1"
                              v-model="form.tipo_combustible"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_combustible1"
                              >Tipo de Combustible</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="caracteristicas"
                              v-model="form.caracteristicas"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="caracteristicas"
                              >caracteristicas</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="lista_documentos"
                              v-model="form.lista_documentos"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="lista_documentos"
                              >Lista de Documentos</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_creacion"
                              v-model="form.fecha_creacion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_creacion"
                              >Fecha Creación</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_modificacion"
                              v-model="form.fecha_modificacion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_modificacion"
                              >Fecha Modificación</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('admin.vehiculos.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
//import vSelect from "vue-select";
export default {
  name: "VehiculoExport", //llegada tambien
  components: { Loading },
  data() {
    return {
      cargando: false,
      form: {
        placa: true,
        tipo_vehiculo_id: true,
        empresa_id: true,
        tarjeta_propiedad: true,
        tipo_carroceria: true,
        numero_motor: true,
        modelo: true,
        peso_vacio: true,
        modelo_re_potenciado: true,
        numero_chasis: true,
        color: true,
        marca: true,
        tipo_capacidad: true,
        estado: true,
        tipo_propiedad: true,
        tipo_combustible: true,
        caracteristicas: true,
        lista_documentos: true,
        fecha_creacion: true,
        fecha_modificacion: true,
      },
      filtros: {
        placa: null,
        tipo_vehiculo_id: null,
        empresa_id: null,
        tipo_carroceria: null,
        numero_motor: null,
        modelo: null,
        numero_chasis: null,
        color: null,
        marca: null,
        tipo_capacidad: null,
        estado: null,
        tipo_propiedad: null,
        tipo_combustible: null,
      },

      listasForms: {
        tipos_vehiculos: [],
        empresas: [],
        tipos_carrocerias: [],
        colores: [],
        marcas: [],
        tipos_capacidad: [],
        estados: [],
        tipos_propiedad: [],
        tipos_combustible: [],
      },
    };
  },

  methods: {
    /*    async getTiposVehiculos() {
      await axios.get("/api/admin/tiposVehiculos/lista").then((response) => {
        this.listasForms.tipos_vehiculos = response.data;
      });
    }, */

    async getTiposVehiculos(tipo_vehiculos) {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 1,
            not_id: tipo_vehiculos,
          },
        })
        .then(function (response) {
          let respuesta = response.data;
          me.listasForms.tipo_vehiculos = respuesta;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getEmpresas() {
      await axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    async getTipoCarroceria() {
      await axios.get("/api/lista/9").then((response) => {
        this.listasForms.tipos_carrocerias = response.data;
      });
    },
    async getColores() {
      await axios.get("/api/lista/11").then((response) => {
        this.listasForms.colores = response.data;
      });
    },
    async getMarcas() {
      await axios.get("/api/lista/12").then((response) => {
        this.listasForms.marcas = response.data;
      });
    },
    async getTiposCapacidad() {
      await axios.get("/api/lista/13").then((response) => {
        this.listasForms.tipos_capacidad = response.data;
      });
    },

    getEstado() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    async getTiposPropiedad() {
      await axios.get("/api/lista/23").then((response) => {
        this.listasForms.tipos_propiedad = response.data;
      });
    },
    async getCombustibles() {
      await axios.get("/api/lista/24").then((response) => {
        this.listasForms.tipos_combustible = response.data;
      });
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/vehiculos/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getTiposVehiculos();
    await this.getEmpresas();
    await this.getTipoCarroceria();
    await this.getColores();
    await this.getMarcas();
    await this.getTiposCapacidad();
    await this.getEstado();
    await this.getTiposPropiedad();
    await this.getCombustibles();
    this.cargando = false;
  },
};
</script>
